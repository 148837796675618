<template>
  <div class="navigation-drawer-main">
    <v-navigation-drawer
      :value="show"
      left
      absolute
      temporary
      @input="drawerUpdated"
      width="260"
      color="#E4E4E7"
    >
      <!-- chatbot histories -->
      <HistoryComponent class="flex-grow-1" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import HistoryComponent from "@/components/chat/History";
import topLogoImage from "@/assets/logos/pinoybot-logo-120.png";

export default {
  props: {
    drawer: Boolean,
    isHistory: Boolean,
  },
  components: { HistoryComponent },
  data() {
    return {
      group: null,
      mainDrawer: true,

      items: [
        {
          path: `/home`,
          title: "Home",
          icon: "mdi-home-outline",
          name: ["Home"],
        },
        {
          path: "/playground",
          title: "Playground",
          icon: "mdi-message-text-outline",
          name: ["playground"],
        },
        {
          path: "/manage-bots",
          title: "Manage Bots",
          icon: "mdi-robot",
          name: ["Manage Bots"],
        },
        {
          path: "",
          title: "History",
          icon: "mdi-history",
          name: ["History"],
        },
        // {
        //   path: "/settings",
        //   title: "Settings",
        //   icon: "mdi-cog-outline",
        //   name: ["settings"],
        // },
        //
        //{
        //  path: "",
        //  title: "Account",
        //  icon: "mdi-shield-outline",
        //  name: ["Account"],
        //},
        {
          path: "",
          title: "Profile",
          icon: "mdi-account-outline",
          name: ["Profile"],
        },
        {
          path: "",
          title: "Submit a Bug",
          icon: "mdi-bug-outline",
          name: ["Bug"],
        },
      ],
    };
  },

  methods: {
    drawerUpdated(val) {
      this.$emit("drawerUpdated", val);
    },

    goTo(route, title) {
      if (route.startsWith("/")) {
        this.$router.push(route);
      }
    },

    showCommingSoon(path) {
      if (!path) {
        globalComingSoonHandler(
          "New features and enhancements coming soon!",
          "Coming Soon"
        );
      }
    },
  },

  computed: {
    topLogo() {
      return topLogoImage;
    },
    show: {
      get() {
        return this.drawer;
      },
      set(v) {
        this.drawer = v;
      },
    },
  },
};
</script>

<style></style>
