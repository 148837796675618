<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      width="350px"
      class="sidebar-menu"
      color="#F1F5F9"
    >
      <v-list-item class="px-4 d-flex align-center mt-10 justify-space-around">
        <div class="sidebar-logo">
          <img
            lazy-src="logo"
            :src="!mini ? topLogo : iconLogo"
            class="mb-2"
            style="width: 150px"
            v-if="!mini"
            @click.stop="goTo('/home')"
          />
          <img
            v-else
            lazy-src="logo"
            :src="iconLogo"
            class="mb-2"
            style="width: 40px"
          />
        </div>

        <v-btn icon @click.stop="mini = !mini" class="ml-auto">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <div class="px-5">
        <v-divider></v-divider>
      </div>

      <Menu />
    </v-navigation-drawer>
  </div>
</template>

<script>
import topLogoImage from "@/assets/logos/pinoybot-200px.png";
import pinoyBotIcon from "@/assets/logos/pinoybot-icon-200px.png";
import Menu from "@/components/template/dashboard/Menu";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Menu,
  },
  data() {
    return {
      drawer: true,
      items: [
        { title: "Home", icon: "mdi-home", path: "/home" },
        {
          title: "Playground",
          icon: "mdi-chat",
          path: `/dashboard/playground/`,
        },
        {
          title: "Notifications",
          icon: "mdi-bell",
          path: `/dashboard/notifications/`,
        },
        {
          title: "Manage Bots",
          icon: "mdi-robot",
          path: `/dashboard/manage-bots/`,
        },
        { title: "History", icon: "mdi-history", path: `/dashboard/history/` },
        // {
        //   title: "Settings",
        //   icon: "mdi-cog",
        //   path: `/dashboard/settings/`,
        // },
        //
        //{
        //  title: "Account",
        //  icon: "mdi-shield-account",
        //  path: "",
        //},
        {
          title: "Profile",
          icon: "mdi-account-edit",
          path: "/dashboard/profile/",
        },
        {
          title: "Submit a Bug",
          icon: "mdi-bug-check",
          path: "",
        },
      ],
      mini: false,
    };
  },
  computed: {
    ...mapGetters("bots", ["bots", "activeBot"]),
    topLogo() {
      return topLogoImage;
    },
    iconLogo() {
      return pinoyBotIcon;
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),

    goTo(route, title) {
      if (route.startsWith("/")) {
        if (title == "History") {
          route = route + this.bots[0].id;
        }
        this.$router.push(route);
      }
    },
    goToLogout() {
      this.logout().then(
        () => {
          this.goTo("/home");
        },
        (err) => {
          globalMessageHandler(
            err,
            "Something went wrong. Please try again later."
          );
        }
      );
    },
    showCommingSoon(path) {
      if (!path) {
        globalComingSoonHandler(
          "New features and enhancements coming soon!",
          "Coming Soon"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-menu {
  height: 100%;
  top: 0px;
  transform: translateX(0%);
  width: 350px;
  background-color: rgb(241, 245, 249);
  border-color: rgb(241, 245, 249);
  left: 0px;
  z-index: 1;
  transform: translateX(0%);
  position: fixed;
  height: calc((100% - 0px) - 0px);
  bottom: 0px;
}
.v-navigation-drawer--mini-variant {
  width: 80px !important;
}
.v-list-item--link {
  color: #27272a !important;
}
.v-list-item--active {
  color: #4285f3 !important;
}
</style>
